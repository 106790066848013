import {Injectable} from '@angular/core';
import {LoadingController, PopoverController, ToastController} from '@ionic/angular';
import {environment} from '../../environments/environment.prod';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {FileTransfer, FileUploadOptions, FileTransferObject} from '@ionic-native/file-transfer/ngx';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ServerService {
    isLoading = false;

    constructor(
        private popoverController: PopoverController,
        public loadingController: LoadingController,
        private httpClient: HttpClient,
        private translate: TranslateService,
        public toastController: ToastController,
        private transfer: FileTransfer
    ) {
    }


    async AlertToast(msg) {
        this.toastController.create({
            message: msg,
            duration: 3000,
            animated: true,
            cssClass: 'alert',
            position: 'bottom',
            color: 'primary',
        }).then((obj) => {
            obj.present();
        });
    }

    async Is_LoadingShow() {
        this.isLoading = true;
        return await this.loadingController.create({
            message: this.translate.instant('Page_Message.is_loading'),
            spinner: 'circles',
        }).then(a => {
            a.present().then(() => {
                if (!this.isLoading) {
                    a.dismiss().then();
                }
            });
        });
    }

    async Is_LoadingHide() {
        this.isLoading = false;
        return await this.loadingController.dismiss().then();
    }

    convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() + (60 * 1000));
        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();
        newDate.setHours(hours - offset);
        return newDate;
    }

    convertMonthNameToMonthNumber(monthName: string) {
        let monthNumber: number;
        if (monthName == 'January') monthNumber = 1;
        if (monthName == 'February') monthNumber = 2;
        if (monthName == 'March') monthNumber = 3;
        if (monthName == 'April') monthNumber = 4;
        if (monthName == 'May') monthNumber = 5;
        if (monthName == 'June') monthNumber = 6;
        if (monthName == 'July') monthNumber = 7;
        if (monthName == 'August') monthNumber = 8;
        if (monthName == 'September') monthNumber = 9;
        if (monthName == 'October') monthNumber = 10;
        if (monthName == 'November') monthNumber = 11;
        if (monthName == 'December') monthNumber = 12;
        return monthNumber;
    }

    detectDayOfWeek(weekNumber: number) {
        let Obj_DayOfWeek: any = [];
        let obj_DayOfWeek = [
            {day: 'monday', date: moment().day('monday').week(weekNumber + 1).format('YYYY-MM-DD')}
            , {day: 'tuesday', date: moment().day('tuesday').week(weekNumber + 1).format('YYYY-MM-DD')}
            , {day: 'wednesday', date: moment().day('wednesday').week(weekNumber + 1).format('YYYY-MM-DD')}
            , {day: 'thursday', date: moment().day('thursday').week(weekNumber + 1).format('YYYY-MM-DD')}
            , {day: 'friday', date: moment().day('friday').week(weekNumber + 1).format('YYYY-MM-DD')}
            , {day: 'saturday', date: moment().day('saturday').week(weekNumber + 1).format('YYYY-MM-DD')}
            , {day: 'sunday', date: moment().day('sunday').week(weekNumber + 1).add(7, "day").format('YYYY-MM-DD')}
        ];
        Obj_DayOfWeek.push(obj_DayOfWeek);
        return Obj_DayOfWeek;
    }

    getFirstDayOfWeek(weekNumber: number) {
        let Obj_DayOfWeek = this.detectDayOfWeek(weekNumber)[0].filter(p => p.day == 'monday');
        return Obj_DayOfWeek;
    }

    getLastDayOfWeek(weekNumber: number) {
        let Obj_DayOfWeek = this.detectDayOfWeek(weekNumber)[0].filter(p => p.day == 'sunday');
        return Obj_DayOfWeek;
    }

    async User_Login(apiUrl, postData): Promise<unknown> {
        return new Promise(resolve => {
            var url = environment.baseURL + apiUrl;
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            };
            return this.httpClient.post<any>(url, postData, httpOptions)
                .subscribe((response) => {
                    let data = {Status: 1, Data: response};
                    resolve(data);
                }, (err) => {
                    let Obj_Error = JSON.parse(JSON.stringify(err));
                    let data = {Status: 0, ErrMsg: Obj_Error['error']['errors'].exceptionMessage};
                    resolve(data);
                });
        });
    };

    async ForgotPassword(apiUrl, userName): Promise<unknown> {
        return new Promise(resolve => {
            var url = environment.baseURL + apiUrl;
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            };
            return this.httpClient.post<any>(url, JSON.stringify(userName), httpOptions)
                .subscribe((response) => {
                    let data = {Status: 1, Data: response};
                    resolve(data);
                }, (err) => {
                    let Obj_Error = JSON.parse(JSON.stringify(err));
                    let data = {Status: 0, ErrMsg: Obj_Error['error']['errors'].exceptionMessage};
                    resolve(data);
                });
        });
    };

    async PostApi(apiUrl, postData): Promise<unknown> {
        return new Promise(resolve => {
            var url = environment.baseURL + apiUrl;
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': 'Bearer ' + localStorage.getItem('login_Token')
                })
            };
            return this.httpClient.post<any>(url, postData, httpOptions)
                .subscribe((response) => {
                    let data = {Status: 1, Data: response};
                    resolve(data);
                }, (err) => {
                    let Obj_Error = JSON.parse(JSON.stringify(err));
                    let data = {Status: 0, ErrMsg: Obj_Error['error']['responseException'].exceptionMessage};
                    resolve(data);
                });
        });
    };

    Post_UploadImageFile(file, apiUrl) {
        var url = environment.baseURL + apiUrl;
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('login_Token')
            })
        };
        var formData: any = new FormData();
        formData.append('file', file);
        return new Promise((resolve, reject) => {
            this.httpClient.post(url, formData, httpOptions).subscribe(data => {
                let Res = {Status: 1, Data: data};
                resolve(Res);
            }, (error) => {
                reject(error);
            });
        });
    }

    Post_UploadImageBase64(Image, apiUrl) {
        const fileTransfer: FileTransferObject = this.transfer.create();
        var url = environment.baseURL + apiUrl;
        let options: FileUploadOptions = {
            fileKey: "file",
            fileName: "IMG.jpg",
            mimeType: "image/jpg, image/jpeg, image/png",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('login_Token')
            },
        }
        return new Promise((resolve, reject) => {
            fileTransfer.upload(Image, url, options).then((data) => {
                let Res = {Status: 1, Data: JSON.parse(data['response'])};
                resolve(Res);
            }, error => {
                let Obj_Error = JSON.parse(JSON.stringify(error));
                let data = {Status: 0, ErrMsg: Obj_Error['error']['responseException'].exceptionMessage};
                reject(data);
            });
        });
    };
}
