import {PopoverController, ModalController, NavController} from '@ionic/angular';
import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {Router, NavigationExtras} from '@angular/router';
import {ServerService} from '../../_service/service.service';
import {TranslateService} from '@ngx-translate/core';
import {ModalLogoutQuestionPage} from "../../modal/modal-logout-question/modal-logout-question.page";

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
    @Input('Title') Title = '';
    @Input('Active') Active = '';
    @Input('Previous') Previous = false;
    @Input('Create') Create = false;
    @Input('directory') Directory = false;
    @Input('Info') Info = false;
    @Input('Token') Token? = false;
    @Input('User') Obj_User: any;
    @Input('SubActive') SubActive: any;
    sideMenuOpen: boolean = false;
    isToken: boolean = false;

    constructor(
        private router: Router,
        private translate: TranslateService,
        private navCtrl: NavController,
        private serverService: ServerService,
        private modalController: ModalController
    ) {
    }

    ngOnInit() {
        this.isToken = localStorage.getItem('login_Token') == null
    }

    ngDoCheck() {
        this.ngOnInit()
    }

    openSideMenu() {
        this.sideMenuOpen = true;
    }

    closeSideMenu() {
        this.sideMenuOpen = false;
    }

    detect_Language(key) {
        let language = '';
        if (key == 'title')
            language = this.translate.instant('Page_Menu.' + key);
        if (key == 'new')
            language = this.translate.instant('Page_Menu.function-' + key);
        if (key == 'profile')
            language = this.translate.instant('Page_Menu.function-' + key);
        if (key == 'support')
            language = this.translate.instant('Page_Menu.function-' + key);
        if (key == 'create')
            language = this.translate.instant('Page_Menu.function-' + key);
        if (key == 'setting')
            language = this.translate.instant('Page_Menu.function-' + key);
        if (key == 'sigout')
            language = this.translate.instant('Page_Menu.function-' + key);
        if (key == 'sigup')
            language = this.translate.instant('Page_Menu.function-' + key);
        if (key == 'sigin')
            language = this.translate.instant('Page_Menu.function-' + key);
        return language;
    }

    detect_SubLanguage(key) {
        let language = '';
        if (key == 'new-sub')
            language = this.translate.instant('Page_Menu.function-' + key);
        if (key == 'profile-sub')
            language = this.translate.instant('Page_Menu.function-' + key);
        if (key == 'support-sub')
            language = this.translate.instant('Page_Menu.function-' + key);
        if (key == 'setting-sub')
            language = this.translate.instant('Page_Menu.function-' + key);
        return language;
    }

    async _is_Submit(flag) {
        this.isToken = true;
        let navigationExtras: NavigationExtras = {
            state:
                {
                    Option: flag
                }
        };
        this.router.navigate(['/sigin'], navigationExtras);
    }

    async _is_Previous() {
        this.navCtrl.back();
    }

    async _is_Logout() {
        const modal = await this.modalController.create({
            component: ModalLogoutQuestionPage
            , animated: true
            , cssClass: 'modal-logout-question'
            , showBackdrop: true
            , backdropDismiss: false
        });
        modal.onWillDismiss().then((Res) => {
            if (Res.data.res == 'logout') {
                localStorage.removeItem('login_Token');
                localStorage.removeItem('login_Id');
                localStorage.removeItem('login_Name');
                localStorage.removeItem('login_Mobile');
                localStorage.removeItem('login_Avatar');
                localStorage.removeItem('login_Username');
                this.Obj_User.fullName = '';
                this.Obj_User.profilePicUrl = '../../../assets/img-home/Avatar_001.png';
                this.isToken = false;
                if (this.router.url === '/home') {
                    if (localStorage.getItem('login_Role') !== 'Admin') {
                        localStorage.removeItem('login_Role');
                    } else {
                        localStorage.removeItem('login_Role');
                        window.location.reload();
                    }
                } else {
                    this.router.navigate(['/home']);
                }
            }
        });
        return modal.present();
    }

    async unRegisterDevice() {
        // let postData = {
        //   "id": localStorage.getItem('n_id') || null
        // }
        // this.serverService.PostApi('api/userDevices/unregisterDevice', postData).then((result) => { });
    }

    actionMenu() {
        this.sideMenuOpen = true
    }


    isSubmenuActive() {
        return this.SubActive == 'product' ? 0 : this.SubActive == 'service' ? 1 : 2
    }
}