import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {Camera} from '@ionic-native/camera/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {CKEditorModule} from 'ng2-ckeditor';
import {MenuModule} from './component/menu/menu.module';
import {Device} from '@ionic-native/device/ngx';
import {FCM} from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
// import {Camera} from '@awesome-cordova-plugins/camera/ngx';
import {AndroidPermissions} from '@awesome-cordova-plugins/android-permissions/ngx';
import {LocalNotifications} from "@awesome-cordova-plugins/local-notifications/ngx";
import {ClipboardModule} from "ngx-clipboard";

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule
        , HttpClientModule
        , IonicModule.forRoot({animated: false})
        , MenuModule
        , ClipboardModule
        , TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
        , AppRoutingModule
    ],
    providers: [
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
        , StatusBar
        , CKEditorModule
        , Camera
        , AndroidPermissions
        , LocalNotifications
        , FileTransfer
        , Device
        , FCM
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
