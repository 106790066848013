import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuComponent} from './menu.component';
import {FormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from "@angular/router";


@NgModule({
    declarations: [MenuComponent],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        IonicModule,
        TranslateModule.forChild(),
    ],
    exports: [
        MenuComponent
    ]
})
export class MenuModule {
}
