import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {TranslateConfigService} from "./providers/translate-config.service";
import {StatusBar} from "@ionic-native/status-bar/ngx";
import {Platform} from "@ionic/angular";
import {FCM} from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import {Device} from "@ionic-native/device/ngx";
import {Router} from "@angular/router";
import {ServerService} from "./_service/service.service";
import {AndroidPermissions} from '@awesome-cordova-plugins/android-permissions/ngx';
import {LocalNotifications} from '@awesome-cordova-plugins/local-notifications/ngx';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    constructor(
        private translate: TranslateService,
        private translateConfigService: TranslateConfigService,
        private statusBar: StatusBar,
        private platform: Platform,
        private fcm: FCM,
        private device: Device,
        public router: Router,
        public serverService: ServerService,
        private androidPermissions: AndroidPermissions,
        private localNotifications: LocalNotifications
    ) {

        this.initializeApp();
    }

    extrasShareLinkParams(url: string) {
        let splitUrl = url.split('?')
        let urlRedirect = splitUrl[0];
        let multipleParams = splitUrl[1].split('&');
        this.router.navigate([urlRedirect], {
            queryParams: Object.assign(multipleParams.map(param => {
                const obj = param.split('=');
                return JSON.parse('{' + obj[0] + ':' + obj[1] + '}')
            }))
        });
    }

    async initializeApp() {
        this.platform.ready().then(async () => {
            if (this.platform.is('android') || this.platform.is('ios')) {
                this.statusBar.overlaysWebView(false);
                this.statusBar.styleLightContent();
                try {
                    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
                        result => console.log('Has permission?', result.hasPermission),
                        err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
                    );
                    this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.CAMERA, this.androidPermissions.PERMISSION.GET_ACCOUNTS]);
                    let token = await this.fcm.getToken();
                    let postData = {
                        "userId": localStorage.getItem('login_Id'),
                        "deviceType": this.platform.is('android') ? 1 : 2,
                        "identifier": this.device.uuid,
                        "pushId": token,
                    }
                    this.serverService.PostApi('api/userDevices/registerDevice', postData).then((result) => {
                        if (result['Status'] == 1) {
                            localStorage.setItem('n_id', result['Data']['result'].id);
                            localStorage.setItem('p_id', token);
                        }
                    });
                    // -----------------------------------------------------------------------------------------
                    this.fcm.onNotification().subscribe(data => {
                        // this.localNotifications.schedule({
                        //     text: data.body,
                        //     foreground: true
                        // });
                        if (data.wasTapped) {
                            console.log("Received in background");
                        } else {
                            console.log("Received in foreground");
                        }
                    });
                    // -----------------------------------------------------------------------------------------
                    this.fcm.onTokenRefresh().subscribe(async token => {
                        let postData = {
                            "userId": localStorage.getItem('login_Id'),
                            "deviceType": this.platform.is('android') ? 1 : 2,
                            "identifier": this.device.uuid,
                            "pushId": token,
                        }
                        this.serverService.PostApi('api/userDevices/registerDevice', postData).then((result) => {
                            if (result['Status'] == 1) {
                                localStorage.setItem('n_id', result['Data']['result'].id);
                                localStorage.setItem('p_id', token);
                            }
                        });
                    });
                    // -----------------------------------------------------------------------------------------
                    this.fcm.hasPermission().then(hasPermission => {
                        if (hasPermission) {
                            console.log("Has permission!");
                        }
                    })

                } catch (e) {
                    console.log(e)
                }
            }
        });
        let isLanguage = localStorage.getItem('isLanguage');
        if (isLanguage == null) {
            this.translateConfigService.getDefaultLanguage();
            localStorage.setItem('language', this.translateConfigService.getDefaultLanguage());
            this.translate.use(localStorage.getItem('language'));
        } else {
            this.translate.use(localStorage.getItem('language'));
        }
        if (window.location.href.indexOf('privacy') >= 0) {
            this.router.navigate(['/privacy']);
        } else {
            if (window.location.href.indexOf('/directory-desktop/') >= 0) {
                this.extrasShareLinkParams(window.location.href.split(window.location.host + '/')[1])
            } else {
                if (this.platform.is('android') || this.platform.is('ios')) {
                    localStorage.setItem('Platform', "");
                    this.router.navigate(['/main']);
                } else {
                    localStorage.setItem('Platform', "WEB");
                    this.router.navigate(['/home']);
                }
            }

        }

    }
}