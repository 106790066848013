import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./auth.guard";

const routes: Routes = [
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
    },
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'language',
        loadChildren: () => import('./pages/language/language.module').then(m => m.LanguagePageModule)
    },
    {
        path: 'sigin',
        loadChildren: () => import('./pages/sigin/sigin.module').then(m => m.SiginPageModule)
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
    },
    {
        path: 'main',
        loadChildren: () => import('./pages/main/main.module').then(m => m.MainPageModule)
    },
    {
        path: 'single-post',
        loadChildren: () => import('./pages/single-post/single-post.module').then(m => m.SinglePostPageModule)
    },
    {
        path: 'menu',
        loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuPageModule)
    },
    {
        path: 'create-new-post',
        loadChildren: () => import('./pages/create-new-post/create-new-post.module').then(m => m.CreateNewPostPageModule)
    },
    {
        path: 'create-new-post-information',
        loadChildren: () => import('./pages/create-new-post-information/create-new-post-information.module').then(m => m.CreateNewPostInformationPageModule)
    },
    {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'profile-edit',
        loadChildren: () => import('./pages/profile-edit/profile-edit.module').then(m => m.ProfileEditPageModule)
    },
    {
        path: 'create-new-post-information',
        loadChildren: () => import('./modal/create-new-post-information/create-new-post-information.module').then(m => m.CreateNewPostInformationPageModule)
    },
    {
        path: 'support',
        loadChildren: () => import('./pages/support/support.module').then(m => m.SupportPageModule)
    },
    {
        path: 'setting',
        loadChildren: () => import('./pages/setting/setting.module').then(m => m.SettingPageModule)
    },
    {
        path: 'modal-resetpassword-information',
        loadChildren: () => import('./modal/modal-resetpassword-information/modal-resetpassword-information.module').then(m => m.ModalResetpasswordInformationPageModule)
    },
    {
        path: 'modal-send-otp-information',
        loadChildren: () => import('./modal/modal-send-otp-information/modal-send-otp-information.module').then(m => m.ModalSendOtpInformationPageModule)
    },
    {
        path: 'modal-sigup-information',
        loadChildren: () => import('./modal/modal-sigup-information/modal-sigup-information.module').then(m => m.ModalSigupInformationPageModule)
    },
    {
        path: 'modal-change-system-information',
        loadChildren: () => import('./modal/modal-change-system-information/modal-change-system-information.module').then(m => m.ModalChangeSystemInformationPageModule)
    },
    {
        path: 'modal-logout-question',
        loadChildren: () => import('./modal/modal-logout-question/modal-logout-question.module').then(m => m.ModalLogoutQuestionPageModule)
    },
    {
        path: 'profile-edit-experience',
        loadChildren: () => import('./pages/profile-edit-experience/profile-edit-experience.module').then(m => m.ProfileEditExperiencePageModule)
    },
    {
        path: 'profile-edit-certificate',
        loadChildren: () => import('./pages/profile-edit-certificate/profile-edit-certificate.module').then(m => m.ProfileEditCertificatePageModule)
    },
    {
        path: 'profile-edit-farm',
        loadChildren: () => import('./pages/profile-edit-farm/profile-edit-farm.module').then(m => m.ProfileEditFarmPageModule)
    },
    {
        path: 'privacy',
        loadChildren: () => import('./pages/privacy/privacy-page/privacy-page.module').then(m => m.PrivacyPageModule),
    },
    {
        path: 'email-support/:flag',
        loadChildren: () => import('./pages/email-support/email-support/email-support.module').then(m => m.EmailSupportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'directory',
        loadChildren: () => import('./pages/directory/directory.module').then(m => m.DirectoryModule),
    },
    {
        path: 'directory-desktop',
        loadChildren: () => import('./pages/desktop-directory/desktop-directory.module').then(m => m.DesktopDirectoryModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
